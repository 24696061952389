import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/docs.wild.plus/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { Code } from "gatsby-theme-docz/src/components/Code";
import { Modal } from "./Modal.tsx";
import { PropsTable } from "./Modal.props.tsx";
import { ModalDialog, StoryScene, ModalBackdrop } from "./stories/common";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "modal"
    }}>{`Modal`}</h1>
    <p>{`Modal component to render children on top of your content w/ a backdrop.`}</p>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <Code className="bash" mdxType="Code">{`yarn add @madebywild/styled-modal`}</Code>
    <Code className="js" mdxType="Code">{`import { Modal } from '@madebywild/styled-modal'`}</Code>
    <h2 {...{
      "id": "props-table"
    }}>{`Props table`}</h2>
    <Props of={PropsTable} mdxType="Props" />
    <h2 {...{
      "id": "examples"
    }}>{`Examples`}</h2>
    <h3 {...{
      "id": "simple-modal-w-default-portal-target-documentbody"
    }}>{`Simple Modal w/ default portal target `}<inlineCode parentName="h3">{`document.body`}</inlineCode></h3>
    <p>{`By default, the modal uses a focus trap, click-away-listener as well as a lock on body scroll.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React, { useState } from "react";
import styled from "styled-components";
import { Modal } from "@madebywild/styled-modal";

const ModalWrapper = styled.div\`
  height: 500px;
  width: 300px;
  background-color: lightsteelblue;
  padding: 20px;
  height: 100%;
  font-family: sans-serif;
\`;

function Scene() {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);

  return (
    <div>
      <button onClick={handleOpen}>Open Modal</button>

      <Modal isOpen={isOpen} onClose={handleClose} portalTarget={document.getElementById("custom-root")}>
        <ModalWrapper>
          <h1>Modal Title</h1>
          <button onClick={handleClose}>Close Modal</button>
        </ModalWrapper>
      </Modal>
    </div>
  );
}
`}</code></pre>
    <h3 {...{
      "id": "simple-modal-w-custom-portal-target"
    }}>{`Simple Modal w/ custom portal target`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React, { useState } from "react";
import styled from "styled-components";
import { Modal } from "@madebywild/styled-modal";

function App() {
  return (
    <>
      <div id="custom-root" />
      <Scene />
    </>
  );
}

const ModalWrapper = styled.div\`...\`;

function Scene() {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);

  return (
    <div>
      <button onClick={handleOpen}>Open Modal</button>

      <Modal isOpen={isOpen} onClose={handleClose} portalTarget={document.getElementById("custom-root")}>
        <ModalWrapper>
          <h1>Modal Title</h1>
          <button onClick={handleClose}>Close Modal</button>
        </ModalWrapper>
      </Modal>
    </div>
  );
}
`}</code></pre>
    <h3 {...{
      "id": "modal-w-custom-backdrop"
    }}>{`Modal w/ custom `}<inlineCode parentName="h3">{`Backdrop`}</inlineCode></h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React, { useState } from "react";
import styled from "styled-components";
import { Modal } from "@madebywild/styled-modal";

const ModalWrapper = styled.div\`...\`;

const Backdrop = styled.div\`
  height: 100%;
  width: 100%;
  background-color: rgba(0, 187, 255, 0.5);
\`;

function Scene() {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);

  return (
    <div>
      <button onClick={handleOpen}>Open Modal</button>

      <Modal
        isOpen={isOpen}
        backdrop={<Backdrop />}
        onClose={handleClose}
        portalTarget={document.getElementById("custom-root")}
      >
        <ModalWrapper>
          <h1>Modal Title</h1>
          <button onClick={handleClose}>Close Modal</button>
        </ModalWrapper>
      </Modal>
    </div>
  );
}
`}</code></pre>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <Playground __position={3} __code={'() => {\n  const [isOpen, setIsOpen] = React.useState(false)\n  const handleClose = () => setIsOpen(false)\n  const handleOpen = () => setIsOpen(true)\n\n  return (\n    <>\n      <StoryScene\n        title=\"Simple Modal\"\n        description=\"This example demonstrates the usage of a simple modal component.\"\n        onOpen={handleOpen}\n      >\n        <Modal\n          isOpen={isOpen}\n          isBodyScrollLockDisabled\n          backdrop={<ModalBackdrop />}\n          onClose={handleClose}\n        >\n          <ModalDialog onClose={handleClose} />\n        </Modal>\n      </StoryScene>\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Code,
      Modal,
      PropsTable,
      ModalDialog,
      StoryScene,
      ModalBackdrop,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [isOpen, setIsOpen] = React.useState(false);

        const handleClose = () => setIsOpen(false);

        const handleOpen = () => setIsOpen(true);

        return <>
        <StoryScene title="Simple Modal" description="This example demonstrates the usage of a simple modal component." onOpen={handleOpen} mdxType="StoryScene">
      <Modal isOpen={isOpen} isBodyScrollLockDisabled backdrop={<ModalBackdrop mdxType="ModalBackdrop" />} onClose={handleClose} mdxType="Modal">
        <ModalDialog onClose={handleClose} mdxType="ModalDialog" />
      </Modal>
    </StoryScene>
      </>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      